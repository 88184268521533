.teamWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.teamRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.sectionTitleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.teamWrapper .sectionTitleWrapper
{
  flex-direction: row;
  align-items: center;
}
.teamWrapper .sectionTitleWrapper .titleDiv
{
  width: 80%;
}
.bulbImage
{
  width: 100px;
  height: auto;
}
.bulbImage img
{
  filter:drop-shadow(rgba(254, 243, 32, 0.16) 0px 3px rgba(255, 169, 49, 0.23) 0.23 0px 3px 6px);
}
.bulbImg
{
  height:auto;width:200px;transform:translateX(-50px);
}
.sectionTitle {
  font-size: 12vh;
  font-weight: 800;
  color: var(--dark-color);
}
.teamRowDiv {
  min-height: 200px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.teamRowDiv  p {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 90%;
}
.teamCardWrapper img {
  width: 100%;
  height: auto;
  position: relative;
  border: 2px solid var(--background);
}
.teamCardWrapper img::before {
  content: '';
  display: flex;
  position: absolute;
  height: 80%;
  width: 80%;
  border: 2px solid #fff;
}
.teamCardOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(17, 17, 17, 0.795);
  color: #fff;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.4s ease;
}
.teamCardWrapper:hover .teamCardOverlay {
  opacity: 1;
}

.teamCardWrapper:hover .teamName,
.teamCardWrapper:hover .teamDesignation,
.teamCardWrapper:hover .teamSocials {
  transform-origin: right;
  animation: topShift 0.5s ease;
}
.teamName {
  font-size: 21px;
  text-transform: uppercase;
  font-weight: 600;
}
.teamDesignation {
  font-family: 'Poppins', sans-serif;
  margin: 6px 0;
  color: #c4c3c3;
}
.teamSocials {
  margin: 12px auto;
}
.teamSocials  a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  margin: 0 6px;
}
.seeAll {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 300px;
}

.seeAll .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: uppercase;
  width: 150px;
  height: 150px;
  border: 1px solid var(--dark-color);
  border-radius: 50%;
  transition: 0.4s ease;
}
.seeAll:hover .circle {
  animation: changeBorder 1s 1;
}

@media screen and (max-width: 1000px) {
  .teamCardWrapper {
    height: auto;
  }
  .teamCardOverlay {
    opacity: 1;
    background: rgba(17, 17, 17, 0.349);
  }
}

@media screen and (max-width:480px) {
  .teamCardOverlay
  {
    /* align-self: flex-end; */
    justify-content: flex-end;
  }
  .bulbImage
  {
    display: none;
  }
  .teamRowDiv p
  {
    font-size: 20px;
    width: 100%;
  }
}
@keyframes topShift {
  from {
    transform: translateY(20px) rotate(-5deg);
  }
  to {
    transform: translateY(0) rotate(0);
  }
}
@keyframes changeBorder {
  0% {
    border-radius: 50%;
    transform: rotate(0);
  }
  10% {
    border-radius: 0;
    transform: rotate(10deg);
  }
  30% {
    border-radius: 30%;
    transform: rotate(90deg);
  }
  60% {
    border-radius: 0;
  }
  100% {
    border-radius: 50%;
  }
}
