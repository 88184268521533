.landingSection
{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    padding: 0 5vw;
}
.landingSection .landingCenter
{
    width: 70vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.landingDetails
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    color: var(--gray-color);
   
}
.landingDetails .navPath
{
  animation :rtl 1s ease;
  
  
}
.landingDetails h1
{
    font-size: 10vh;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 100%;
    animation :rtl 1s ease;      color: var(--gray-color);
   
   
}
.landingDetails .btn-6
{
  animation :rtl 1s ease;
}
.landingImage .circle
{
    width: min(400px,100vw);
    height: min(400px,80vh);
    border: 1px solid var(--dark-color);
    border-radius: 50%;
    position: absolute;
    top: 10%;
    left: -2%;
    background: transparent;
    z-index: 0;
    transform: scale(1.2);
}
.landingSection .landingCenter .landingImage
{
    width: min(500px,90vw);
    overflow: hidden;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
#landingHorizontalImage
{
  max-height: 300px;
  width: 90%;
  z-index: 1;
  animation: ltr 0.5s ease;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.landingSection .landingCenter .landingImage img
{
    width: 100%;
    height: auto;
    object-fit: cover;
}
#abstractVerticalImage
{
  position: absolute;
  height: 80%;
  width: 50%;
  right: -0;
  z-index: 0;
  animation: ltr 1s ease;
}
.btn-6 {
  color: var(--dark-color);
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 50px;
  max-width: 250px;
  margin: 1rem 0;
  text-transform: uppercase;
  border: 1px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: 'Montserrat',sans-serif;
  font-weight: 800;
  transition: 0.5s ease;
  }
  .btn-6 span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: var(--dark-color);
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .btn-6:hover {
    color: var(--light-color);
  }
  .btn-6:hover span {
    width: 225%;
    height: 562.5px;
  }
  .btn-6:active {
    background-color: var(--dark-color);
  }
  
@keyframes rtl {
  from
  {
    transform: translateX(-140%);
  }
  to
  {
    transform: translateX(0);
  }
}
  @keyframes ltr {
    from 
    {
      transform: translateX(50%);

    }
    to
    {
      transform: translateX(0);
    }
    
  }
  .scrollDiv
  {
    /* transform: rotate(-90deg); */
    font-family: 'Poppins',sans-serif;
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 1%;
    bottom: 20%;
    color: var(--gray-color);
  }
.scrollDiv::after
{
  content: '';
  display: flex;
  width: 100px;
  height: 1px;
   background: var(--dark-color);
   margin-left: 10px;
}
.socialsDiv
{
  color: var(--dark-color);
}

.socialsDiv
{
  position: absolute;
  transform: rotate(-90deg);
  right: 5%;
}
.socialsDiv>a
{
  text-decoration: none;
  color: var(--dark-color);
  font-size: 16px;
  margin: 0 6px ;

}

  @media screen and (max-width:900px) {
    .nav
    {
      position: relative;
    }
    .landingSection
    {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      
    }
    .scrollDiv
    {
      display: none !important;
    }
    .landingCenter
    {
      flex-direction: column-reverse;
      width: 90vw !important;
    }
    .landingDetails
    {
      padding-left: 10vw;
    }
    .landingCenter h1
      {
        font-size: 7vh;
      }
    #landingHorizontalImage
    {
      width: 100%;
    }
    #abstractVerticalImage
    {
      /* display: none; */
      width: 90%;
      left: 10%;
    }
    .socialsDiv
    {
      position: relative;
      transform: rotate(0);
      align-self: center;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .socialsDiv>a
    {
      font-size: 23px;
    }
  } 