.page-footer {
  position: relative;

  min-height: 60vh;
  width: 100vw;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  overflow: hidden;
}

.circle {
  position: absolute;
  margin-bottom: -50px;
  overflow: hidden;
}

#circle-img {
  width: 780px;
  transform-origin: bottom;
  filter: var(--filter);
}

.rotated {
  transform: rotate(360deg);
  transition: transform 2s ease-out;
}

.footer-content {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
}

.footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  max-width:95vw;
}

.footer-link {
  font-family: var(--bold-font);
  font-weight: 900;

  cursor: pointer;

  text-decoration: none;

  color: var(--dark-color);
}

.separator {
  width: 25em;

  /* border: 1px solid var(--dark-color); */
  background: var(--dark-color);
  height: 1px;

  margin: 4vh 0;
}

.subtext {
  /* width: 25vw;   */
  text-align: center !important;
  margin-bottom: 4vh;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: var(--dark-color);
}

.backToTop {
  position: absolute;
  right: 0;
  bottom: 10vh;
  text-transform: uppercase;
  transform: rotate(-90deg);
  font-weight: 800;
  letter-spacing: 2px;
  transition: 0.5s ease-in-out;
  color: var(--dark-color);
}
.backToTop:hover {
  transform: rotate(-90deg) translateX(20px);
}

@media screen and (max-width: 600px) {
  .backToTop {
    transform: rotate(0);
    position: relative;
    right: 0;
    bottom: 5px;
  }
  .backToTop:hover {
    transform: rotate(0) translateX(0);
  }
  /* .footer-links {
    gap: 0.5em;
  } */
}
