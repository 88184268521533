

.contactWrapper {
  width: 90vw;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.detailsWrapper,
.formWrapper {
  width:45%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;

  position: relative;color: var(--gray-color);
  
}

.detailsWrapper div {
  margin: 1.2vh 0;
  font-family: 'Poppins',sans-serif;
}

.title {
  font-size: 5em;
  font-weight: 900;

  line-height: 1.2em;

  font-family: 'Montserrat';

  text-transform: uppercase;
}

.detailsWrapper .subText {
  width: 70%;

  line-height: 1.8em;
}

.detailsWrapper a
{
  font-size: 21px;
  font-family: 'Montserrat';
  margin: 0 5px;
  color: var(--gray-color);
}

.formWrapper form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  
}

.formWrapper form input {
  align-self: flex-start;

  width: 70%;

  height: 3em;

  font-size: 1.2em;

  margin: 1.2vh 0;
background: transparent;
  border: none;
  border-bottom: 2px solid var(--dark-color);

  outline: none;
}

#send-mail-btn {
  width: 10em;
  height: 4em;

  text-align: left;

  padding: 0 1em;
  margin: 2vh 0;

  align-self: flex-start;

  outline: none;
  border: none;

  background-color: var(--dark-color);

  color: var(--light-color);

  box-shadow: inset 0 0 0 0 var(--dark-color);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  font-family: 'Montserrat',sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid transparent;
}

#send-mail-btn:hover {
  box-shadow: inset -10em 0 0 0 var(--background);
  border: 1px solid var(--dark-color);
  color: var(--dark-color);
}

@media screen and (max-width: 1024px) {
  .contactWrapper {
    flex-direction: column;
  }
  .detailsWrapper,
  .formWrapper {
    width: 100%;
  }

  .title {
    text-align: center;
  }
  .subText {
    width: 60%;
  }

  .formWrapper form input {
    margin: 2vh auto;
  }
  #send-mail-btn {
    margin: 2vh auto;
  }
}

@media screen and (max-width: 550px) {
  .contactWrapper {
    flex-direction: column;
  }
  .detailsWrapper,
  .formWrapper {
    width: 100%;
  }

  .title {
    font-size: 4em;

    text-align: center;
  }

  .subText {
    width: 80%;
  }

  .formWrapper form input {
    margin: 2vh auto;
  }
  #send-mail-btn {
    margin: 2vh auto;
  }
}
