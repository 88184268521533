.nav {
  width: 100vw;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5vh 5vw;
  z-index: 9998;
}

.logo {
  width: 15vw;
  height: auto;
  display: flex;
 
}

.logoLandscape {
  width: 100%;
  height: auto;
 
}
.letterLogo
{
  width: 50px;
  
}
.writingLogo
{
  transform: scale(0.8);
  margin-left: 6px;
  transform-origin: left; filter: var(--bright-filter);
}
.menu {
  display: flex;
}
.navLink,
.active {
  font-family: var(--bold-font);
  margin: 0 10px;
  color: var(--dark-color);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  letter-spacing: 1px;
  padding: 6px;
}
.navLink:hover,.navLink:active,.navLink:focus,.active:hover,.active:active,.active:focus
{
  color: var(--dark-color) !important;
}
.active::after,
.navLink::after {
  content: '';
  display: flex;
  width: 100%;
  height: 2.5px;
  background: var(--dark-color);
  transition: 0.5s ease;
}
.navLink::after {
  width: 0;
}
.navLink:hover::after {
  width: 100%;
}
.rightMenu {
  display: flex;
  align-items: center;
}
.hamMenu {
  font-size: 25px;
  margin-left: 12px;
  width: 50px;
  height: 50px;
  padding: 2%;
  display: flex;
  align-items: center;
}
.sideNav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  overflow-y: scroll;
  /* width: 300px; */
  width: 0;
  background: var(--dark-color);
  color: var(--light-color);
  transition: 0.4s ease;
  padding-bottom: 10vh;

  z-index: 9998;
}
.openMenu {
  right: 0;
  width: min(500px, 100vw);
  transition: 0.4s ease;
}
.sideNav::-webkit-scrollbar {
  display: none;
}
.sideNav .closeBtn {
  position: absolute;
  top: 10%;
  right: 10%;
  font-size: 20px;
}
.sideNav .closeBtn span {
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  margin-left: 12px;
}
.sideMenu {
  margin-top: 20vh;
  margin-left: 10%;
}
.sideMenu .sideMenuItemDiv {
  margin: 30px auto;
}
.sideMenu .navPath {
  font-family: 'Poppins', sans-serif;
  color: var(--light-color);
  font-size: 11px;
  font-weight: 400 !important;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.sideMenu .sidenavLink {
  /* color: var(--light-color); */
  color: var(--light-color);
  text-decoration: none;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 800;
  -webkit-text-stroke: 1px var(--light-color);
  -webkit-text-fill-color: var(--light-color);
  transition: all 0.4s ease;
}
.sideMenu .sidenavLink:hover,
.sideMenu .sideActive {
  color: transparent;
  -webkit-text-fill-color: transparent;
  transition: 0.4s ease;
}
