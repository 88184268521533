.aboutLanding {
  width: 80vw;
  height: 100vh;
  background: url('../../images/aboutLanding.jpg');
  object-fit: contain;
  margin-bottom: 12vh;
}

.aboutSection {
  position: relative;
}
.aboutDivWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0;
  max-width: 80vw;
}
.aboutDiv {
  padding: 0;
  margin: 20px 0;
}
#abstractImg {
  display: none;
  position: absolute;
  height: 170px;
  width: auto;
  right: 5%;
  top: 110vh;
}
.aboutDivTitle {
  font-size: 21px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 6px;
  color: var(--dark-color);
}
.aboutDivPara {
  font-family: 'Poppins', sans-serif;
  color: var(--gray-color);
}
/* 
.titleWrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.aboutReverse {
  justify-content: flex-end;
  text-align: right;

  padding: 0 5vw;
} */
