.values {
  display: flex;
  flex-direction: column;

  color: var(--dark-color);
}

.values .goal-msg .about-title,
.values .goal-msg .sec-header {
  text-align: center;
}

.values .goal-msg .about-title {
  font-family: Oswald;
}

.values .goal-msg .about-msg {
  width: 50%;
  align-self: center !important;
  text-align: center !important;

  padding: 0 !important;
  margin: 0 !important;
}

.vision-msg {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.vision-msg .header {
  font-size: 3em;
  font-weight: 900;
  font-family: Oswald, sans-serif;
  margin-top: 0;
}

.vision-msg .subtext {
  width: 80%;
  font-size: 1.4em;
}

@media screen and (max-width: 900px) {
  .alt-about-wrapper .msg-wrapper {
    align-items: center;
  }
  .alt-about-wrapper .msg-wrapper .about-msg {
    width: 90%;
    margin: 0 !important;
    padding: 0 !important;
    align-self: center !important;
  }
  .values .about-landing .landing-content {
    font-size: 4em;
  }
}

.landImg {
  width: 100vw;
  height: 100vh;

  margin: 5em 0;
}

.AltImg {
  width: 100vw;
  height: 100vh;

  object-fit: cover;
}

.foundersMessageWrapper {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  background: var(--dark-color);
  min-height: 100vh;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 20vh;
}
.founderImageDiv {
  position: relative;
  height: 100%;
}
.backgroundDiv {
  width: min(400px, 80vw);
  height: 400px;
  background: var(--light-color);
  position: absolute;
}
.imageDiv {
  overflow: hidden;
  object-fit: contain;
  transform: translate(50px, 50px);
}
.imageDiv > img {
  width: min(400px, 80vw);
  height: 400px;
  object-fit: cover;
}

.foundersMessageTitle {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  font-weight: 1000;
  font-size: 8vh;
  color: var(--light-color);
  margin: 0px 0 20px 0;
  position: relative;
}
.foundersMessageTitle span {
  -webkit-text-stroke: 2px var(--light-color);
  color: transparent;
}
.message p {
  font-family: 'Poppins', 'Montserrat', sans-serif;
  font-weight: 400;
  color: var(--light-color);
}
#quoteImg {
  transform: rotate(180deg);
  position: absolute;
  top: -20px;
  left: -80px;
  filter: var(--filter);
}
@media screen and (max-width: 1000px) {
  .foundersMessageWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .foundersMessageTitle
  {
    height: 40vh;
  } */
  .founderImageDiv {
    margin-bottom: 50px;
  }
  #quoteImg {
    transform: rotate(180deg);
    position: absolute;
    top: -20px;
    left: 5vw;
  }
  .foundersMessageTitle {
    text-align: center;
  }
  .backgroundDiv {
    position: relative;
  }
  .imageDiv {
    transform: translate(20px, 20px);
    position: absolute;
    top: 0;
  }
  .message p {
    font-weight: 500;
  }
  .landImg {
    height: 60vh;
  }
  .AltImg {
    height: 60vh;
    overflow: hidden;
  }
}
@media screen and (max-width: 480px) {
  #quoteImg {
    display: none;
  }
}
.designation {
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
}
#signature {
  width: min(200px, 60vw);
  height: auto;
  filter: var(--filter);
}
