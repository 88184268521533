
.gridWrapper {
    position: relative;
    min-height: 100vh;
    width: 90vw;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: flex-start;
    overflow: visible;
    margin:0 auto;
    padding-top: 5vh;

  }
  
  .image-section {
    --num-cols: 3;
    --row-height: 300px;
  
    display: grid;
    align-items: center;
    justify-content: center;
  
    grid-template-columns: repeat(var(--num-cols), 2fr);
    grid-auto-rows: var(--row-height);
  
    gap: 2em;
  
    width: fit-content;
    height: fit-content;
  
    /* overflow: hidden; */
  
    /* padding: 2em; */
    margin: 1em;
  }
  .image-section > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .grid-col-2 {
    grid-column: span 2;
  }
  
  .grid-row-2 {
    grid-row: span 2;
  }
  
  .sticky-section {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 10vh;
  
    width: 25em;
  }
  
  .main-info {
    margin: 0.3em;
  }
  
  .main-info > #detail-title {
    font-size: 1.8em;
    font-family: Montserrat;
    font-weight: 900;
  }
  
  .main-info > .subtext {
    font-size: 1.1em;
    font-family: Lato;
  }
  
  .sub-details {
    margin: 0.4em;
  }
  .sub-details>div
  {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
  }
  .header {
    font-size: 1.3em;
    font-family: Montserrat,sans-serif;
    font-weight: 800;
  }
  
  .subtext {
    font-family: Poppins,sans-serif;
    text-align: left;

  }
  
  @media screen and (max-width: 1400px) {
    .image-section {
      --num-cols: 2;
      --row-height: 200px;
    }
    
  
    .grid-col-2 {
      grid-column: span 1;
    }
  
    .grid-row-2 {
      grid-row: span 1;
    }
  }
  
  @media screen and (max-width: 800px) {
      .gridWrapper
      {
          width: 100vw;
          flex-wrap: wrap;
      }
      .image-section>img
    {
        width: 100%;
        height: auto;
        object-fit: contain;
        
    }
    .image-section {
      /* --row-height: 200px; */
      --num-cols: 1;
      width: 100%;
    }
  
    .grid-col-2 {
      grid-column: span 1;
    }
  
    .grid-row-2 {
      grid-row: span 1;
    }
  }
  