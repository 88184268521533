.portfolioWriting
{
    text-transform: uppercase;
   
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    /* justify-content: center; */

}
.portfolioWriting h1
{
        
    /* transform: rotate(-45deg) translate(10%,-50%); */
    transform-origin: bottom;
    color: var(--dark-color);
    font-weight: 800;
    font-size: 9vh !important;
}
.portfolioWriting h1 span
{
    color: red;
}

.teamRow
{
    justify-content: center;
}

@media screen  and (max-width: 768px) {
   
    .teamRow:nth-of-type(2)
    {
        margin-top: -20vh;
    }
}