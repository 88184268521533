.galleryWrapper {
  padding: 2vh 10vw;
  z-index: 9998;
}
@media screen and (max-width:600px) {
  .galleryWrapper
  {
    padding: 2vh 2vw;
  }
  
}
