
.missingSection {
  min-height: 80vh;
  width: 100vw;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../images/abstract_back.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.missingSection > h1 {
  font-size: 16vh;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: var(--dark-color);
}

.missingSection > h3 {
  text-align: center;
  font-size: 5vh;
  color: var(--dark-color);
  margin-bottom: 4vh;
}
@media screen and (max-width: 500px) {
  .missingSection > h1 {
    font-size: 8vh;
  }
  .missingSection > h3 {
    text-align: center;
    font-size: 2vh;
  }
}
