
.paddingSection
{
  padding: 0 8vw;
}
.cursor
{
  z-index: 9999;
}
.cursor{
  position: fixed;
  width: 25px;
  height: 25px;
  background: transparent;
  transition: 0;
  border-radius: 50%;
  transform: translate(-50%,-50%);
  pointer-events: none;
  mix-blend-mode: exclusion;
 
}
.cursor::after
{
  content: '';
  display: flex;
  width: 100%;
  height: 100%;
  border: 2px solid var(--dark-color);
  background: var(--dark-color);
  top:0;
  left:0; 
  border-radius: 50%;
  
}
/* body .content:hover ~ .cursor {
  mix-blend-mode: screen;
} */




body:hover  .cursor {
  /* transform: scale(1.4); */
  mix-blend-mode: exclusion;
  background: var(--dark-color);
  backdrop-filter: invert();
}

.TitleSection
{
color: var(--dark-color);
font-size: 8vh;
font-weight: 800;
text-transform: uppercase;
}
@media screen and (max-width:900px) {
  .logo
  {
    width: 50vw !important;
  }
  .menu,.cursor
  {
    display: none !important;
  }
  
}



.orderSection
{
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderCardDiv
{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  background: #fff;
  min-width: 30vw;
  margin: 20px auto;
  padding-bottom: 4%;
}

.orderCardDiv>img
{
width: auto;  
height: 200px;
}
.orderCardDetails
{
  font-family: 'Poppins',sans-serif;
  color:#000;
  margin-top: 30px ;
  width: 100%;
  text-align: center;
  margin-bottom:14px
}
.orderCardTitle
{
  font-size: 150%;
  text-transform: uppercase;
  font-weight: 600;
}
.orderCardDesc
{
  font-weight: 500;
  margin-bottom: 20px;
}
.orderBtn
{
  width: 70%;
  background:#000;
  color:#fff;
  font-size: 120%;
  text-align: center;
  text-transform: uppercase;
  margin: 10px auto;
  padding: 10px 20px;
  transition: 0.4s ease;
  border: 2px solid black;
  border-radius: 5px;
}
.orderBtn:hover
{
  background: transparent;
  color: #000;
}
@media screen and (max-width:800px) {
  .orderSection
  {
    flex-direction: column;
  }

  .orderCardDiv
  {
    width: 80vw;
  }
  .orderCardDiv>img
  {
    width: 80%;
    height: auto;
  }
  .TitleSection
   {
     font-size:7vh;
   }
  
}