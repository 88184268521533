
.toggle {
    --size: 3rem;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    outline: none;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    border-radius: 999px;

    transition: all 500ms;
    --ray-size: calc(var(--size) * -0.4);
    --offset-orthogonal: calc(var(--size) * 0.65);
    --offset-diagonal: calc(var(--size) * 0.45);
    transform: scale(0.75);
    color: #00041b;
    box-shadow: inset 0 0 0 var(--size), calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size), var(--offset-orthogonal) 0 0 var(--ray-size), 0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size), 0 var(--offset-orthogonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size), var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size), calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size), var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
    /* box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0; */
  }
  .toggle:checked
  {
    color: #e6e6ff;
    box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
  }
  .toggle:focus
  {
    border: transparent;
    outline: transparent;
  }
  /* .toggle:checked {
   
    
  } */

  
  .toggle {
    z-index: 1;
  }

