.mapWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 90vw;

  height: 60vh;

  margin: 4vh auto;
 
}

.mapWrapper iframe {
  width: 100%;
  height: 100%;
  z-index: 999;
  border: 0;
}
