.about-landing {
  position: relative;
}

.landing-center {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 120vh;

  margin: 4vh auto 8vh auto;
}
.landing-image {
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  object-fit: contain;
  object-position: top;
  position: relative;
  filter: brightness(0.7) contrast(90%);
}


.landing-content
{
  position: absolute;
  font-size: 8vh;
  font-family: 'Poppins',sans-serif;
  text-transform: capitalize;
  word-wrap: break-word;
  color: #fff;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 3px;
  text-shadow: 5px 5px 7px rgb(24, 24, 24);
  font-weight: 600;
}

.scrollDiv {
  transform: rotate(-90deg);
  font-family: 'Poppins', sans-serif;
  /* position: relative; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 1%;
  bottom: 20%;
}
.scrollDiv::after {
  content: '';
  display: flex;
  width: 100px;
  height: 1px;
  background: var(--dark-color);
  margin-left: 10px;
}

@media screen and (max-width: 900px) {
  .landingCenter {
    flex-direction: column-reverse;
    width: 100vw;
  }
  #landingHorizontalImage {
    width: 100%;
  }
  #abstractVerticalImage {
    /* display: none; */
    width: 80%;
    left: 10%;
  }
  .landing-content
  {
    font-size: 8vh;
    width: 94% ;
  }
}
