.alt-about-wrapper {
  width: 60%;
  min-width: 380px;

  justify-self: center;
  align-self: center;

  display: flex;

  flex-direction: column;

  align-items: center;
  justify-content: center;

  margin: 1em 0;

  z-index: 9998;
}

.about-title {
  font-size: 4em;
  margin: 0.5em 0;
  font-family: Montserrat;
  font-weight: 900;
}

.sec-header {
  font-size: 2.2em;
  margin: 0.5em 0.8em;
  font-family: Montserrat;
  font-weight: 600;
}

.msg-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2em;
}

.about-msg {
  font-size: 1.5em;
  width: 80%;
  margin-left: -3em;
  font-family: 'Poppins',sans-serif ;
}

#msg-1,
#msg-3,
#msg-5 {
  padding-left: 4em;
}

#msg-2,
#msg-4,
#msg-6 {
  align-self: flex-end;
  text-align: right;
  padding-right: 4em;
}

.bottom-quote {
  width: 90%;
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  font-size: 2.5em;

  text-align: center;
}
