@import url('https://fonts.googleapis.com/css2?family=Anton&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quintessential&display=swap');
*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none !important;
}
:root
{
  --bold-font:'Oswald',sans-serif;
  --light-color:#fff;
  --background:#fff;
  --dark-color:#111;
  --gray-color:#333;
  --filter: ;
  --bright-filter:brightness(100%);
  

}
::selection
{
  background: var(--dark-color);
  color: var(--light-color);

}
html
{
  scroll-behavior: smooth;
 
}
body {
  margin: 0;
  font-family:var(--bold-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw !important;
  overflow-x: hidden !important;
  position: relative;
  background: var(--background);
  transition: 0.4s ease;
  

}
body::-webkit-scrollbar
{
  display: none;
}

a,a:hover,a:active,a:focus
{
  text-decoration: none !important;
  
}