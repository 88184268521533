.IconCardWrapper
{
    /* min-height: 60vh; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 10vh;
}
.IconCard
{
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #333;
    color: #fff;
    margin: 20px auto;
    padding: 20px 40px;
    border-radius: 5px;
    /* border: 1px solid var(--dark-color); */
    transition: 0.4s ease;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.Icon
{
    width: 100px;
    height: auto;
}
.cardIconImg
{
    width: 100%;
    height: auto;
    filter: invert(); 
    transition: 0.4s ease;
}
.IconCard .cardTitle
{
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 18px;
    margin: 22px auto;
}
.IconCard .cardBody
{
    text-align: center;
    font-family: 'Poppins',sans-serif;
    font-size: 14px;
}
.IconCard:hover
{
    background: var(--light-color);
    color: var(--dark-color);
}
.IconCard:hover .cardIconImg
{
    filter: var(--filter);
}